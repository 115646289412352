import numeral from 'numeral'
import { useEffect } from 'react'

export default function useNumeralLocales() {
  useEffect(() => {
    if (typeof numeral.locales['en-us'] === 'undefined') {
      numeral.register('locale', 'en-us', {
        delimiters: {
          thousands: ',',
          decimal: '.',
        },
        abbreviations: {
          thousand: 'K',
          million: 'M',
          billion: 'B',
          trillion: 'T',
        },
        ordinal: function (number) {
          const b = number % 10
          return ~~((number % 100) / 10) === 1
            ? 'th'
            : b === 1
            ? 'st'
            : b === 2
            ? 'nd'
            : b === 3
            ? 'rd'
            : 'th'
        },
        currency: {
          symbol: '$',
        },
      })

      numeral.locale('en-us')
    }
  })
}
