import axios, { AxiosError, AxiosInstance } from 'axios'
import { useRouter } from 'next/router'
import { createContext, useEffect, useRef } from 'react'
import { ReactNode } from 'react'

import { apiUrl } from '@/env'

export const AxiosContext = createContext<AxiosInstance | null>(null)

const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const instanceRef = useRef(
    axios.create({
      baseURL: `${apiUrl}/api/v1`,
      withCredentials: true,
    })
  )

  const router = useRouter()

  useEffect(() => {
    const errInterceptor = (error: AxiosError) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        router.push('/signout')
      }

      return Promise.reject(error)
    }

    const instance = instanceRef.current
    const interceptor = instance.interceptors.response.use(null, errInterceptor)
    return () => instance.interceptors.response.eject(interceptor)
  }, [router])

  return (
    <AxiosContext.Provider value={instanceRef.current}>
      {children}
    </AxiosContext.Provider>
  )
}

export default AxiosInterceptor
