import Head from 'next/head'
import Header from 'src/components/layout/Header'

export default function Layout({ children }: { children: React.ReactNode }) {
  // Put Header or Footer Here
  return (
    <>
      <Head>
        <title>Bankroll</title>
      </Head>

      <Header />
      {children}
    </>
  )
}
