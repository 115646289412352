// !STARTERCONF This is for demo purposes, remove @/styles/colors.css import immediately
// import '@/styles/colors.css';
import { CssBaseline, GeistProvider } from '@geist-ui/core'
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs'
import { SessionContextProvider } from '@supabase/auth-helpers-react'
import { AppProps } from 'next/app'
import { useState } from 'react'

import '@/styles/globals.css'
import '@/styles/positions.css'

import useNumeralLocales from '@/lib/numeral'

import Layout from '@/components/layout/Layout'
import Seo from '@/components/Seo'

import AxiosInterceptor from '@/pages/axios_interceptor'

function MyApp({ Component, pageProps }: AppProps) {
  // Switch between locales
  useNumeralLocales()

  const [supabase] = useState(() => createBrowserSupabaseClient())

  return (
    <SessionContextProvider
      supabaseClient={supabase}
      initialSession={pageProps.initialSession}
    >
      <GeistProvider>
        <CssBaseline />
        <Layout>
          <Seo />
          <AxiosInterceptor>
            <Component {...pageProps} />
          </AxiosInterceptor>
        </Layout>
      </GeistProvider>
    </SessionContextProvider>
  )
}

export default MyApp
